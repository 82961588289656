<footer bg-1> 
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <modulo posicao="endereco"></modulo>
                </div>
                <div class="col-md-3">  
                    <menu-footer-partial></menu-footer-partial>  
                </div>    
                <div class="col-md-3">
                    <modulo posicao="destinos-menu"></modulo>
                </div>
                <div class="col-md-3">   
                    <modulo posicao="contato" template="footer"></modulo>
                    <modulo posicao="rede-social" template="footer"></modulo> 
                </div>
            </div> 
        </div>
    </div>    
    <copyright></copyright>
</footer>        