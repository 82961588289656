<div class="modulo contato-suporte-modulo">
      <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
            <h3>{{data.titulo}}</h3>
            <h4 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h4>
      </div> 
      <div class="modulo-content">
            <div class="item" *ngIf="data.params.email !== ''">
                  <strong>Suporte: </strong>
                  <a href="mailto:{{data.params.email}}">{{data.params.email}}</a>
            </div>
            <div class="linha" *ngIf="data.params.email !== ''"></div>
            <div class="item" *ngIf="data.params.telefone1 !== '' || data.params.telefone2 !== ''">
                  <strong>Plantão: </strong>
                  <a href="tel:{{data.params.telefone1}}">{{data.params.telefone1}}</a>
                  <span> / </span>
                  <a href="tel:{{data.params.telefone2}}">{{data.params.telefone2}}</a>
            </div>
            <div class="linha" *ngIf="data.params.telefone1 !== '' || data.params.telefone2 !== ''"></div>
      </div>      
</div>      