<div class="dm-modal">
    <div class="dm-modal-title">
        <span>{{title}}</span> 
        <div class="icon" (click)="_close()">
            <i class="material-icons">close</i>
        </div>    
    </div> 
    <div class="dm-modal-content" [ngSwitch]="content">  
        <form class="form-custom" (submit)="entrar()" *ngSwitchCase="'1'">
            <div class="item">
                <div class="form-group">
                    <label>Informe seu e-mail:</label>
                    <div class="input-icon">
                        <i class="material-icons">email</i>
                        <input type="text" [(ngModel)]="dataItem.email" placeholder="nome@provedor.com.br" name="email" />
                    </div>
                </div> 
                <div class="form-group">
                    <label>Informe sua senha:</label>
                    <div class="input-icon">
                        <i class="material-icons">lock</i>
                        <input type="password" [(ngModel)]="dataItem.senha" placeholder="******" name="senha" />
                    </div>
                </div> 
            </div>
            <div class="item item-info">
                <p>Esqueceu sua senha? <a href="javascript:void();" (click)="setContent(2)">Clique Aqui</a></p>
                <p>Deseja se cadastrar? <a href="javascript:void();" (click)="setContent(4)">Clique Aqui</a></p>
            </div>  
            <div class="item item-info">
                <button type="submit" class="btn-three btn-icon center">
                    <i class="material-icons">check</i>
                    <span>Entrar</span>
                </button>   
            </div>
            <div class="item item-buttons" *ngIf="configuracao.login_facebook_ativo || configuracao.login_google_ativo ">
                <div class="center">   
                    <btn-facebook-login [usuarioService]="usuarioService" *ngIf="configuracao.login_facebook_ativo" class="btn-login-social"></btn-facebook-login>
                    <btn-google-login [usuarioService]="usuarioService" *ngIf="configuracao.login_google_ativo" class="btn-login-social"></btn-google-login>
                </div> 
            </div>  
        </form>
        <form (submit)="sendEmail()" class="form form-custom" *ngSwitchCase="'2'">
            <div class="form-group">
                <label>Informe seu e-mail:</label> 
                <input type="text" [(ngModel)]="dataItem.email" name="email" autocomplete="_email" />
            </div>
            <div class="form-group have-code">
                <a href="javascript::void();" title="Código de recuperação de senha" (click)="setContent(3)">
                    <span>Já tenho um código que foi enviado por e-mail.</span>
                    <span class="btn-click">Clique aqui</span>
                </a>
            </div>    
            <div class="form-group">
                <hr>
                <div class="center">
                    <button type="submit" class="btn-two btn-margin">
                        Enviar
                    </button>  
                    <button type="button" class="btn-one" (click)="setContent(1)">
                        Voltar para login
                    </button>  
                </div>    
            </div>
        </form>
        <form (submit)="alterarSenha()" class="form form-custom" *ngSwitchCase="'3'">
            <div class="form-group">
                <label>Informe o código enviado no seu e-mail:</label>  
                <input type="text" [(ngModel)]="dataItem.codigo" name="codigo" autocomplete="_codigo" integer />
            </div>
            <div class="form-group">
                <label>Informe a nova senha:</label>  
                <input type="password" [(ngModel)]="dataItem.senha_nova" name="senha_nova" autocomplete="_senha_nova"  />
            </div>
            <div class="form-group">
                <div class="center">
                    <button type="submit" class="btn-two btn-margin">
                        Alterar
                    </button> 
                    <button type="submit" class="btn-one btn-margin" (click)="setContent(2)">
                        Voltar
                    </button> 
                </div>    
            </div>    
        </form>
        <form (submit)="cadastrar()" class="form form-custom" *ngSwitchCase="'4'">
            <div class="form-group">
                <label>Informe seu nome*:</label>
                <input type="text" [(ngModel)]="dataItemCadastro.nome" name="nome" autocomplete="_nome" />
            </div>
            <div class="form-group">
                <label>Informe seu e-mail*:</label>
                <input type="text" [(ngModel)]="dataItemCadastro.email" name="email" autocomplete="_email" />
            </div>
            <div class="form-group">
                <label>Informe a senha*:</label>
                <input type="password" [(ngModel)]="dataItemCadastro.senha" name="senha" autocomplete="_senha" />
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Data de Nascimento*:</label> 
                        <input type="text" [(ngModel)]="dataItemCadastro.data_nascimento" name="data_nascimento" autocomplete="_data_nascimento" mask="99-99-9999" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group"> 
                        <label>Informe seu celular*:</label> 
                        <input type="text" [(ngModel)]="dataItemCadastro.telefone" name="telefone" autocomplete="_telefone" phoneMask />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Informe seu CPF*:</label>  
                        <input type="text" [(ngModel)]="dataItemCadastro.cpf" name="cpf" autocomplete="_cpf" mask="999.999.999-99" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Informe seu RG*:</label>   
                        <input type="text" [(ngModel)]="dataItemCadastro.rg" name="rg" autocomplete="_rg" />
                    </div>
                </div>
            </div>
            <div class="form-group">
                <hr> 
                <div class="center">
                    <button type="submit" class="btn-two btn-cadastrar btn-margin">
                        Cadastrar
                    </button> 
                    <button type="submit" class="btn-one" (click)="setContent(1)">
                        Login
                    </button>    
                </div>    
            </div>
        </form> 
    </div>    
</div>
<loader *ngIf="loader"></loader>
