<div class="sidebar-menu" [ngClass]="{'slide-left': show,'hide':!show}">
      <div class="sidebar-menu-header">
          <div class="close" (click)='_click()'>
              <i class="material-icons">close</i>
          </div>    
      </div>
      <div class="sidebar-menu-content">
        <div class="item" [ngClass]="{'active': currentUrl == '/'}">
            <a routerLink="/" title="Home">
                Home 
            </a>
        </div>
        <div class="item" *ngFor="let c of categorias" routerLinkActive="active">
            <a routerLink="/servicos/{{c.apelido}}" [title]="c.titulo">
                {{c.titulo}}
            </a>
        </div>  
        <div class="item" routerLinkActive="active">
            <a routerLink="/sobre" title="Sobre">
                Sobre
            </a>
        </div>
        <div class="item" routerLinkActive="active">
            <a routerLink="/contato" title="Contatos">
                Contato
            </a>
        </div>
        <div class="item" routerLinkActive="active">
            <a routerLink="/minha-conta" title="Minha Conta">
                Minha Conta
            </a>
        </div>
      </div>    
  </div>