<div class="modulo modulo-destinos">
      <div class="modulo-title" data-wow-delay="0.1s" *ngIf="data.mostrar_titulo">
            <div class="container">
                  <h2 class="section-title-h2">{{data.titulo}}</h2>
                  <h3 *ngIf="data.mostrar_subtitulo" class="section-title-h3">{{data.subtitulo}}</h3>
            </div>
      </div>
      <div class="modulo-content">
            <div class="container">
                  <section class="destinos slider-items" #slider>
                        <div class="slider-item" *ngFor="let d of data.destinos;let i=index;">
                              <destino-item [data]="d"></destino-item>  
                        </div>
                  </section>
                  <div class="slide-navigate-item template-2 prev" (click)="prev()">
                        <div class="content">
                            <i class="material-icons">navigate_before</i>
                        </div>
                    </div>
                    <div class="slide-navigate-item template-2 next" (click)="next()">
                        <div class="content">
                            <i class="material-icons">navigate_next</i>
                        </div>
                    </div> 
            </div>   
      </div>      
</div>
      
