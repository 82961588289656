import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsuarioService } from 'src/app/services/usuario.service';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';
import { ValidatorService } from 'src/app/services/validator.service';
import { StorageService } from 'src/app/services/storage.service';
import { GtagService } from 'src/app/services/gtag.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'usuario-login-modal',
  templateUrl: './usuario-login-modal.component.html',
  styleUrls: ['./usuario-login-modal.component.scss']
})
export class UsuarioLoginModal implements OnInit {

  public dataItem:any         = {};  
  public dataItemCadastro:any = {};  
  public content      = 1;
  public title        = "Login";
  public loader       = false; 
  public number1      = 0;
  public number2      = 0;
  public soma         = null;
  public configuracao = null;

  constructor(
    public dialog: MatDialogRef<UsuarioLoginModal>,
    @Inject(MAT_DIALOG_DATA) public data,
    public usuarioService: UsuarioService,
    private app: AppService,
    private api: ApiService,
    private validator: ValidatorService,
    private storage: StorageService,
    private gtag: GtagService,
    private _data: DataService
  ){ 

    this.content        = typeof(this.data.content) != "undefined" ? this.data.content : 1;  
    this.usuarioService = typeof(this.data.usuarioService) != "undefined" ? this.data.usuarioService : 1;  
    this.configuracao   = this._data.getConfiguracao();
  
  }
  /**
   * 
   * Realiza o login
   * 
   */
  entrar(){

    try{
      
      this.loader = true; 

      this.api.loja().login(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.storage.setUsuario(response.data);
          this._close(); 

          setTimeout(() => {
            this.usuarioService.emitUsuarioLogin.emit(response.data); 
          },100);

        }else{

          this.app.info("Houve um problema ao logar. Tente novamente ou entre em contato com nossa equipe.","danger");

        }

      }, (response) => {

        this.loader = false;
        let errors  = this.app.formatError(response); 

        this.app.info(errors.message,"danger");

      });

    }catch(e){

      this.app.info(e.message,"danger");

    }

  }
  /**
   * 
   * Send E-mail
   * 
   */
  sendEmail(){

    try{

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.email,"email","- Informe o E-mail.");
      this.validator.isEmail(this.dataItem.email,"email","- O E-mail informado é inválido.");

      if(!this.validator.passes()){

        let errors = this.validator.getMessagesHtml();
        this.app.info(errors,"danger"); 
        
      }else{

        this.loader = true;  

        this.api.loja().email_senha(this.dataItem.email).subscribe(response => {

          this.loader = false;

          if(response.status == 1){  

            this.app.info("Verifique o código que foi enviado para o seu e-mail.","success");
            this.content = 3;

          }else{

            this.app.info("Houve um erro e não foi possível completar a requisição. Tente novamente.","danger");

          }

        },(response) => {

          this.loader = false;
          let error   = this.app.formatError(response);
          this.app.info(error.message,"info");

        });

      }

    }catch(e){

      this.loader = false;
      this.app.info(e.message,"danger");

    }

  }
  /**
   * 
   * Cadastrar
   * 
   */
  cadastrar(){

    try{


      this.validator.clear();
      this.validator.isEmpty(this.dataItemCadastro.nome,"nome","- Informe o Nome.");
      this.validator.isEmpty(this.dataItemCadastro.email,"email","- Informe o E-mail.");
      this.validator.isEmail(this.dataItemCadastro.email,"email","- E-mail inválido.");
      this.validator.isEmpty(this.dataItemCadastro.senha,"senha","- Informe a Senha.");
      this.validator.isEmpty(this.dataItemCadastro.senha,"data_nascimento","- Informe o seu Nascimento.");
      this.validator.isEmpty(this.dataItemCadastro.telefone,"telefone","- Informe o Celular.");
      this.validator.isEmpty(this.dataItemCadastro.cpf,"cpf","- Informe o CPF.");
      this.validator.validCpf(this.dataItemCadastro.cpf,"cpf","- Informe o CPF.");
      this.validator.isEmpty(this.dataItemCadastro.rg,"rg","- Informe o RG.");

      if(this.validator.passes()){

        this.loader = true;

        this.api.loja().usuario().store(this.dataItemCadastro).subscribe(response => {

          this.loader = false;

          if(response.status == 1){ 

            this.usuarioService.emitUsuarioLogin.emit(response.data);
            this.storage.setUsuario(response.data); 
            this._close();

            this.app.info("Seu cadastro foi realizado com sucesso.","success");
            this.gtag.cadastroSite(); 

          }else{

            this.app.info("Houve um problema no processamento. Tentenovamente ou informe a nossa equipe.","danger"); 

          }

        }, (response) => {

          this.loader = false;
          let error   = this.app.formatError(response);

          this.app.info(error.message,"danger"); 

        });

      }else{

        let errors = this.validator.getMessagesHtml();
        this.app.info(errors,"danger"); 

      }

    }catch(e){

      this. loader = false;

    }


  }
  /**
   * 
   * Close
   * 
   */
  _close(){

    this.dialog.close(); 

  }
  /**
   * 
   * Init dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      email: "", 
      senha: "",
      codigo: "",
      senha_nova: ""
    }

  }
  /**
   * 
   * Init dataItem
   * 
   */
  initDataItemCdastro(){

    this.dataItemCadastro = {
      nome: "",
      email: "",
      data_nascimento: "",
      senha: "",
      telefone: "",
      cpf: "",
      rg: "" 
    }

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.usuarioService.emitUsuarioLogin.subscribe(usuario => {

      if(usuario != null){
        this._close();
      }

    });

  }
  /**
   * 
   * Alterar senha
   * 
   * @param value 
   * 
   */
  alterarSenha(){

    try{

      this.validator.clear();

      this.validator.isEmpty(this.dataItem.email,"email","- Informe o E-mail.");
      this.validator.isEmpty(this.dataItem.codigo,"codigo","- Informe o Código.");
      this.validator.isEmpty(this.dataItem.senha_nova,"senha_nova","- Informe a Nova Senha.");

      if(this.validator.passes()){

        this.loader = true; 

        this.api.loja().update_senha(this.dataItem).subscribe(response => {

          this.loader = false;

          if(response.status == 1){

            this.content = 1;
            this.soma    = null;
            this.setNumbers();
            this.initDataItem();
            this.app.info("A sua senha foi alterada com sucesso.","success");

          }else{

            this.app.info("Houve um problema ao alterar a senha. Tente novamente.","danger");

          }

        },(response) => {

          this.loader = false;
          let error   = this.app.formatError(response);
          this.app.info(error.message,"danger");

        });

      }else{

        this.app.info(this.validator.getMessagesHtml(),"danger"); 

      }


    }catch(e){

      this.app.info(e.message,"danger");

    }

  }
  /**
   * 
   * Set content
   * 
   * @param value 
   * 
   */
  setContent(value){

    this.content = value; 

    switch(this.content){
      case 1:
        this.title = "Login";
      break;
      case 2:
        this.title = "Recuperação de senha";
      break;
      case 4: 
        this.title = "Cadastro de Usuário";
      break;
    }

  }
  /**
   * 
   * 
   * 
   */
  setNumbers(){

    this.number1 = Math.floor(Math.random() * 10);
    this.number2 = Math.floor(Math.random() * 10);

  }
  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit():void{
    this.initDataItem(); 
    this.initDataItemCdastro();
    this.onData();
    this.setNumbers();
  }

}
