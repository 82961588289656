import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class CartService {

  public emitirDeleteItem          = new EventEmitter();
  public emitirUpdateTotalCart     = new EventEmitter(); 
  public emitirUpdateDesconto      = new EventEmitter();
  public emitirUpdateCupom         = new EventEmitter();
  public emitirAddItem             = new EventEmitter();
  public updateCartDataByPagamento = new EventEmitter();
  public deleteItemInCartEmit      = new EventEmitter();     

  constructor(){}    

  
}
