import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dm-cart-prime',
  templateUrl: './dm-cart-prime.component.html',
  styleUrls: ['./dm-cart-prime.component.scss']
})
export class DmCartPrimeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
