<div class="module-newsletter">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-center">
        <h3 *ngIf="data.mostrar_titulo">{{data.titulo}}</h3>
      </div>
      <div class="col-md-8">
        <div class="content-form">
          <span class="title" *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</span>
          <form class="form-custom" (submit)="send()">
              <div class="item item-input">
                <label>Digite o seu nome:</label>
                <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="{{'Informe seu nome' | translate}}" />
              </div>    
              <div class="item item-email item-input">  
                <label>Digite o seu e-mail:</label>
                <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="{{'Informe seu e-mail' | translate}}" />
              </div>
              <div class="item item-button">
                <button> 
                  <span>enviar</span>
                </button> 
              </div>
          </form>
        </div> 
      </div>
    </div> 
  </div>  
</div>  
<loader *ngIf="loader"></loader>
